<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>會員管理中心</b-breadcrumb-item>
            <b-breadcrumb-item active>受眾管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">受眾管理</h4>
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            :to="{ name: 'AudienceCreate', query: { branch_id: query.branch_id }}"
            ><i class="fa fa-plus"></i>新增受眾</b-button
          >

          <b-select
            :options="branchOptions"
            v-model="query.branch_id"
            @input="handlePageResetWithoutReload"
            :disabled="!this.checkPermission([consts.AUDIENCE_LIST_VIEW_BRANCH])"
          ></b-select>
          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input
              v-model="query.keyword"
              v-on:keyup.enter="handlePageResetWithoutReload"
              placeholder="搜尋"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handlePageResetWithoutReload"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-table
              striped
              hover
              responsive
              :items="audiences"
              :fields="fields"
              :busy="isLoading"
              @sort-changed="handleSort"
              no-local-sorting
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(actions)="data">
                <b-button
                  v-if="checkPermission([consts.AUDIENCE_LIST_VIEW])"
                  variant="inverse-primary"
                  :to="{
                    name: 'AudienceView',
                    params: {
                      audienceId: data.item.id,
                    },
                  }"
                >
                  <span class="mdi mdi-eye mr-1"></span>查看
                </b-button>
                <b-button
                  v-if="checkPermission([consts.AUDIENCE_MODIFY])"
                  class="ml-2"
                  variant="inverse-warning"
                  :to="{
                    name: 'AudienceEdit',
                    params: {
                      audienceId: data.item.id,
                    },
                  }"
                >
                  <span class="mdi mdi-lead-pencil mr-1"></span>編輯
                </b-button>
                <b-button
                  v-if="checkPermission([consts.AUDIENCE_DELETE])"
                  class="ml-2"
                  variant="inverse-danger"
                  @click="handleDelete(data.item.id, data.item.title)"
                >
                  <span class="mdi mdi-delete mr-1"></span>刪除
                </b-button>
                <b-dropdown variant="inverse-secondary" class="ml-2">
                  <template slot="button-content">
                    <i class="mdi mdi-menu"></i>
                  </template>
                  <b-dropdown-item
                    v-if="checkPermission([consts.AUDIENCE_LIST_VIEW])"
                    class="ml-2"
                    variant="inverse-secondary"
                    :to="{
                      name: 'AudienceCustomersList',
                      params: {
                        audienceId: data.item.id,
                      },
                    }"
                    >受眾名單</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="checkPermission([consts.AUDIENCE_MODIFY])"
                    class="ml-2"
                    @click="handleSync(data.item.id, data.item.title)"
                    ><i class="fa fa-repeat"> </i> 更新名單</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="checkPermission([consts.AUDIENCE_SYNC_LINE])"
                    class="ml-2"
                    @click="handleSyncToLine(data.item.id, data.item.title)"
                    >同步至LINE</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="checkPermission([consts.OA_PUSH_CREATE])"
                    class="ml-2"
                    :to="{
                      name: 'PushMessageDetail',
                      params: {
                        messageID: 'create',
                      },
                      query: {
                        audience_id: data.item.id,
                      },
                    }"
                    >群發訊息</b-dropdown-item
                  >
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import audienceApi from "@/apis/audience";
import moment from "moment";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";
import {updateQueryFromRoute, updateUrlWithoutReload} from "@/utils/updateUrl";

export default {
  components: { CustomPagination },
  mixins: [ paginationMixin ],
  data() {
    return {
      consts: consts,
      isLoading: false,
      audiences: [],
      fields: [
        {
          key: "title",
          label: "標題",
        },
        {
          key: "source",
          label: "受眾來源",
          formatter(source) {
            switch (source) {
              case "filter":
                return "條件查詢";
              case "import":
                return "手動匯入";
            }
          },
        },
        {
          key: "count",
          label: "數量",
        },
        {
          key: "status",
          label: "狀態",
          formatter(status) {
            switch (status) {
              case "waiting":
                return "已排程";
              case "processing":
                return "處理中";
              case "sync-to-line":
                return "同步至Line";
              case "done":
                return "已完成";
              case "failed":
                return "同步失敗";
              case "import-failed":
                return "匯入失敗";
              case "sync-line-failed":
                return "同步至 LINE 失敗";
            }
          },
        },
        {
          key: "synced_at",
          label: "最後更新時間",
          formatter(synced_at) {
            return moment(synced_at).isValid() ? moment(synced_at).format("yyyy-MM-DD HH:mm:ss") : null;
          },
        },
        {
          key: "created_at",
          label: "建立時間",
          formatter: (value) => {
            return moment(value).isValid()
              ? moment(value).format("YYYY-MM-DD HH:mm:ss")
              : undefined;
          },
          sortable: true,
          sortKey: "created_at",
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      totalRows: 0,
      showLoading: false,
      showLoadingUpload: false,
      keyword: null,
      query: {
        page: 1,
        per_page: 10,
        keyword: null,
        order_by: "created_at",
        order_direction: "desc",
        branch_id: null,
      },
      initialized: false,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("general", ["currentBranch", "myBranches"]),
    ...mapGetters("route", ["routeQuery"]),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
    branchOptions() {
      let options = this.myBranches
        .map(branch => {
          let branchName = `${branch.branch_code} ${branch.name}`
          return {
            value: branch.id,
            text: branchName
          }
        })
      options = [
        {
          value: null,
          text: '請選擇分店'
        },
        ...options
      ]
      return options
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
    if (this.currentBranch) {
      this.query.branch_id = this.currentBranch.id
    }
    if (this.$route.query.branch_id) {
      this.query.branch_id = this.$route.query.branch_id
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrlWithoutReload(this.query, this.$store, this.$router);
      },
    },
    routeQuery() {
      if (!this.initialized) return;
      this.getAudiences();
    },
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async init() {
      if (!this.checkPermission([consts.AUDIENCE_LIST_VIEW])) {
        this.$swal
          .fire({
            type: "error",
            text: "你沒有權限訪問此頁面",
            confirmButtonColor: "#d33",
          })
          .then(() => {
            this.$router.push({ name: "OrganizationList" });
          });
      } else {
        await this.getAudiences();
        this.initialized = true;
      }
    },
    async handleSyncToLine(autoawardId, title) {
      this.$swal
        .fire({
          type: "warning",
          title: "同步受眾到 LINE？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>確定要同步受眾 ${title} 嗎?</div>
              </div>
            </div>`,
          confirmButtonText: "更新",
          cancelButtonText: "不更新",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await audienceApi.syncAudience(autoawardId);
              await this.getAudiences();
            } catch (error) {
              console.log(error);
            } finally {
              this.isLoading = false;
            }
          }
        });
    },
    async handleSync(autoawardId, title) {
      this.$swal
        .fire({
          type: "warning",
          title: "更新受眾？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>確定要更新受眾 ${title} 嗎?</div>
              </div>
            </div>`,
          confirmButtonText: "更新",
          cancelButtonText: "不更新",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await audienceApi.syncAudience(autoawardId);
              await this.getAudiences();
            } catch (error) {
              console.log(error);
            } finally {
              this.isLoading = false;
            }
          }
        });
    },
    async handleDelete(autoawardId, title) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>確定要刪除受眾${title} ?</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await audienceApi.deleteAudience(autoawardId);
              await this.getAudiences();
            } catch (error) {
              console.log(error);
            }
            this.isLoading = false;
          }
        });
    },

    handleSort(ctx) {
      this.query.order_by = ctx.sortBy;
      this.query.order_direction = ctx.sortDesc ? "desc" : "asc";
      this.handlePageResetWithoutReload();
    },

    async getAudiences() {
      try {
        this.isLoading = true;
        let params = Object.fromEntries(
          /* eslint-disable no-unused-vars */
          Object.entries(this.query).filter(([_, value]) => value !== null)
          /* eslint-enable no-unused-vars */
        );
        const { data } = await audienceApi.getAudiences(params);
        this.totalRows = data.meta.total;
        this.audiences = data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.modal-backdrop {
  opacity: 0.5;
}

.advance-search {
  color: #337ab7;

  &:hover {
    color: #23527c;
    text-decoration: underline;
  }
}
</style>
